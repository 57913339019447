require('./tel_cell.js');

$('.toggle-menu').click(function () {
  $('body').toggleClass('menu-open');
});

$('.slider').slick({
  autoplay: true,
  arrows: false,
  cssEase: 'ease',
  edgeFriction: 0.5,
  fade: true,
});

$('.notice-ticker-list').carouselTicker({
    speed: 1,
    delay: 50,
    reverse: true,
});

$('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    // startDate: '-100000d'
});

var $container = jQuery('.gallery-items');
$container.imagesLoaded(function () {
  $container.isotope({
    itemSelector: '.gallery-item',
    layoutMode: 'masonry',
    masonry: {
      columnWidth: '.gallery-item',
    }
  });
});

$('#gallery_filters').on('click', 'button', function () {
  var filterValue = $(this).attr('data-filter');
  $container.isotope({
    filter: filterValue
  });
});

$('.gallery-filter').each(function (i, buttonGroup) {
  var $buttonGroup = $(buttonGroup);
  $buttonGroup.on('click', 'button', function () {
    $buttonGroup.find('.btn-primary').removeClass('btn-primary');
    $(this).addClass('btn-primary');
  });
});

$('.same-height').matchHeight();

$('.file-upload').fileupload({
    headers: {
        'X-CSRF-Token': $('input[name="_token"]').val()
    },
    // dataType: 'json',
    add: function (e, data) {
        // files: filesList,
        data.submit();
        let file_name = $(this).attr('data-name');
        let icon_name = $(this).attr('data-icon');
        console.log('1',file_name);
        $('.' + file_name + '_progress .progress-bar').css(
            'width', 0 + '%'
        );
        $('.' + file_name + '_icon').removeClass('d-none');
        $('.' + file_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
    },
    progressall: function (e, data) {
        // let file_name = $(this).attr('name');
        let file_name = $(this).attr('data-name');
        let icon_name = $(this).attr('data-icon');
        // console.log('2',file_name);
        let progress = parseInt(data.loaded / data.total * 100, 10);
        $('.' + file_name + '_icon').removeClass('d-none');
        $('.' + file_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
        $('.' + file_name + '_progress').removeClass('d-none').fadeIn();
        $('.' + file_name + '_progress .progress-bar').css(
            'width', progress + '%'
        );
        if (progress == 100) {
            $('.' + file_name + '_progress').fadeOut();
        }
    },
    done: function (e, data) {
        // console.log(data.result.status);
        // $('#items').empty().append(data.result);
        // let file_name = $(this).attr('name');
        let file_name = $(this).attr('data-name');
        let icon_name = $(this).attr('data-icon');
        // console.log(file_name,data._response.result.status);
        if (data.result.status == 'failed') {
            // console.log('failed',file_name);
            $('.' + file_name + '_error').empty().append(data._response.result.message).removeClass('d-none');
            $('.' + file_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
        }
        if (data.result.status == 'success') {
            console.log('success',file_name);
            // console.log(data._response.result.file_url);
            $('.' + file_name + '_preview').removeClass('d-none').attr('src', data._response.result.file_url).parent().attr('href', data._response.result.file_url);
            $('.' + file_name + '_hide').removeClass('d-none');
            $('.' + file_name + '_icon').addClass('d-none');
            $('.' + file_name + '_error').addClass('d-none');
        }
    },
});

$('.file-uploads').fileupload({
    headers: {
        'X-CSRF-Token': $('input[name="_token"]').val()
    },
    progressall: function (e, data) {
        let file_name = $(this).attr('data-name');
        let progress = parseInt(data.loaded / data.total * 100, 10);
        $('.' + file_name + '_progress').removeClass('d-none').fadeIn();
        $('.' + file_name + '_progress .progress-bar').css(
            'width', progress + '%'
        );
        if (progress == 100) {
            $('.' + file_name + '_progress').fadeOut();
        }
        $('#'+file_name).prop('disabled', true);
    },
    add: function (e, data) {
        // files: filesList,
        data.submit();
        let file_name = $(this).attr('data-name');
        let icon_name = $(this).attr('data-icon');
        $('.' + file_name + '_progress .progress-bar').css(
            'width', 0 + '%'
        );
        $('.' + file_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
    },
    done: function (e, data) {
        let file_name = $(this).attr('data-name');
        let icon_name = $(this).attr('data-icon');
        if (data.result.status == 'failed') {
            $('.' + file_name + '_error').empty().append(data.result.message).removeClass('d-none');
            // $('.' + file_name + '_error').empty().append(data._response.result.message).removeClass('d-none');
            $('.' + file_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
            $('#'+file_name).prop('disabled', false);
        }else{
            $('.' + file_name + '_error').addClass('d-none');
            $('.' + file_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
            $('#files_'+file_name).empty().append(data.result);
            $('#'+file_name).prop('disabled', false);
            $('.remove-file').on('click', function () {
                let file_name = $(this).attr('data-name');
                let icon_name = $(this).attr('data-icon');
                let url = $(this).attr('data-url');
                let file_id = $(this).attr('data-id');
                let file_type = $(this).attr('data-type');
                $.confirm({
                    title: 'Are you sure to remove?',
                    content: 'Confirm to delete this' + ' <strong>' + file_name + '</strong> ' + 'permanently.',
                    type: 'blue',
                    buttons: {
                        ok: {
                            text: "Confirm",
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function () {
                                if(file_id){
                                    $('.'+file_name+ '_' + file_id).addClass('d-none');
                                    $('.'+file_name +'_icon_' + file_id).removeClass('d-none').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
                                }else{
                                    $('.' + file_name + '_icon').removeClass('d-none');
                                    $('.' + file_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
                                }
                                $.get(url, function (data) {
                                    if (data == 'success') {
                                        $('.' + file_name + '_preview').addClass('d-none').attr('src', null);
                                        $('.' + file_name + '_hide').addClass('d-none');
                                        $('.' + file_name + '_icon').removeClass('d-none');
                                        $('.' + file_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
                                    }
                                    if (data == 'files_success') {
                                        $('.' + file_id + '_image').addClass('d-none');
                                        $('.' + file_name + '_icon').removeClass('d-none');
                                        $('.' + file_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
                                    }
                                })
                            }
                        },
                        cancel: function () {}
                    }
                });
            });
        }
    },
});

$('.remove-file').on('click', function () {
    let file_name = $(this).attr('data-name');
    let icon_name = $(this).attr('data-icon');
    let url = $(this).attr('data-url');
    let file_id = $(this).attr('data-id');
    $.confirm({
        title: 'Are you sure to remove?',
        content: 'Confirm to delete this' + ' <strong>' + file_name + '</strong> ' + 'permanently.',
        type: 'blue',
        buttons: {
            ok: {
                text: "Confirm",
                btnClass: 'btn-primary',
                keys: ['enter'],
                action: function () {
                    if(file_id){
                        $('.'+file_name+ '_' + file_id).addClass('d-none');
                        $('.'+file_name +'_icon_' + file_id).removeClass('d-none').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
                    }else{
                        $('.' + file_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
                        $('.' + file_name + '_icon').removeClass('d-none');
                    }
                    $.get(url, function (data) {
                        if (data == 'success') {
                            console.log(file_name);
                            $('.' + file_name + '_preview').addClass('d-none').attr('src', null);
                            $('.' + file_name + '_hide').addClass('d-none');
                            $('.' + file_name + '_icon').removeClass('d-none');
                            $('.' + file_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
                        }
                        if (data == 'files_success') {
                            $('.' + file_id + '_image').addClass('d-none');
                            $('.' + file_name + '_icon').removeClass('d-none');
                            $('.' + file_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
                        }
                    })
                }
            },
            cancel: function () {}
        }
    });
});
