var input = document.querySelector("#tel_cell"),
  errorMsg = document.querySelector("#error-msg"),
  validMsg = document.querySelector("#valid-msg");

// here, the index maps to the error code returned from getValidationError - see readme
var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

$(input).intlTelInput({
  initialCountry: "auto",
  separateDialCode: true,
  formatOnDisplay: false,
  geoIpLookup: function (success,failure) {
    $.get("https://ipinfo.io", function () {}, "jsonp").always(function (resp) {
      var countryCode = (resp && resp.country) ? resp.country : "";
      success(countryCode);
    });
  }
});

$("form").submit(function () {
  var dialCode = $("#tel_cell").intlTelInput("getSelectedCountryData").dialCode;
  $("#tel_cell_country_code").val(dialCode);
});

var reset = function() {
  input.classList.remove("error");
  errorMsg.innerHTML = "";
  errorMsg.classList.add("d-none");
  validMsg.classList.add("d-none");
  $('#verify_otp_field').addClass('d-none');
};

if (input) {
  input.addEventListener('blur', function() {
  reset();
  if (input.value.trim()) {
    var num = $(input).intlTelInput("isValidNumber");
    if (num) {
      validMsg.classList.remove("d-none");
      $('#verify_otp_field').removeClass('d-none');
    } else {
      input.classList.add("error");
      var errorCode = $(input).intlTelInput("getValidationError");
      errorMsg.innerHTML = errorMap[errorCode];
      errorMsg.classList.remove("d-none");
      
      $('#verify_otp_field').addClass('d-none');
    }
  }
});

input.addEventListener('change', reset);
input.addEventListener('keyup', reset);
}